import { lazy } from 'react';
import { withSuspenseFallback } from '../utils/withSuspenseFallback';

const Ratings = lazy(() => import('../features/products/survey-keyboard/pages/ReportPage/Ratings'));

const basePath = '/products';

const surveyKeyboard = [
  {
    path: `${basePath}/survey-keyboard/reports`,
    component: withSuspenseFallback(Ratings),
  },
];

export default { surveyKeyboard };

import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import api from '../service/api';

const ProtectedRoutes = () => {
  const [auth, setAuth] = useState(null);
  const [cookies] = useCookies(true);

  useEffect(() => {
    const checkAuthToken = async () => {
      const authResp = await checkTokenisValid();
      setAuth(authResp);
    };

    checkAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkTokenisValid = async () => {
    try {
      const { jwtToken } = cookies;
      if (typeof jwtToken === 'string' && jwtToken.trim() !== '') {
        const responseToken = await api.post('validate-token', { jwtToken }, { withCredentials: true });
        if (responseToken.data.status !== 'success') {
          return false;
        }
        localStorage.setItem('token', `Bearer ${responseToken.data.data.validateToken}`);
        return true;
      }
      return false;
    } catch (err) {
      console.error('error', err);
      return false;
    }
  };

  // eslint-disable-next-line no-nested-ternary
  return auth === null ? <> </> : auth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;

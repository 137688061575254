// PÁGINAS DE LOGIN

import { Suspense, lazy } from 'react';
import BaseLayout from '../layouts/BaseLayout';

// PÁGINAS DE ADMIN
const ManageAccess = lazy(() => import('../pages/Admin/ManageAccess/ManageAccess'));
const Config = lazy(() => import('../pages/Admin/Config'));
const Perfil = lazy(() => import('../pages/Perfil'));
const Users = lazy(() => import('../pages/User'));

export const adminRoutes = [
  {
    path: '/users',
    component: (
      <Suspense fallback={<BaseLayout loading />}>
        <Users />
      </Suspense>
    ),
  },
  {
    path: '/config',
    component: (
      <Suspense fallback={<BaseLayout loading />}>
        <Config />
      </Suspense>
    ),
  },
  {
    path: '/perfil/:id',
    component: (
      <Suspense fallback={<BaseLayout loading />}>
        <Perfil />
      </Suspense>
    ),
  },
  {
    path: '/manage-access',
    component: (
      <Suspense fallback={<BaseLayout loading />}>
        <ManageAccess />
      </Suspense>
    ),
  },
];
